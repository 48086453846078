.device-rank-avatar {
  --border-radius: 0
}

.device-item {
  --border-radius: 25px;
  margin-bottom: 5px;
  --inner-padding-bottom: 5px;
  --padding-bottom: 5px;
  //--min-height:65px;
  //--background:#df6572;
  //--background-opacity: 0.5;
  //background-size: 80%;
}

.device-box {
  padding: 15px;
  overflow-y: scroll;
}

.device-list {
  background: inherit;
  border: 1px solid #ddd;
  padding: 15px 10px;
  border-radius: 0 0px 15px 15px;

}

.device-list-h1{
  max-height: 80vh;
  overflow: scroll;
}

.device-list-h2{
  max-height: 80vh;
  overflow: scroll;
}

.device-list-h3{
  max-height: 80vh;
  overflow: scroll;
}

.content-ion-rank {
  //font-family: Epilogue, sans-serif;
  background: url("../../pages/epoch/img/background.png");
  background-size: 100%;
  min-height: calc(100vh - 44px);
  height: auto;
}

.rank-text {
  color: #fff;
  height: 30px;
  min-width: 80px;
  //max-width: 150px;
  text-align: center;
  line-height: 30px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background: #152955;
  border-radius: 5px 5px 0 0;
  font-weight: 800;
  font-family: Epilogue, sans-serif;
}

.rank-digital{
  padding: 0 0 0 0px;
  font-size: 18px;
  font-weight: 600;
  color: #152955;
  text-align: center;
  font-family: Epilogue, sans-serif;
}

.overflow-cst{
  width: 100%;
  overflow:hidden;
}

.epoch-rank-modal{
  --width: 90%;
  --height: 68%;
  --max-width: 700px;
  --border-radius: 15px;
  --background:#fff;
  //opacity: 0.9;
}

.epoch-rank-info{
  --width: 80%;
  --height: 40%;
  --max-width: 600px;
  --border-radius: 15px;
  --background:#fff;
  overflow-y: scroll;
  //opacity: 0.9;
}

