.form-padding{
    padding: 10px 0;
}

.form-fee-text{
    font-size: 12px;
    color: #8c8c8c;
}

.form-amount{
    font-size: 32px;
}

.form-address{
    font-size: 14px;
}

.form-button-div{
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 5px;
    z-index: 999;
    background-color: #fff;
}

.trf-box{
    height: 60vh ;
    overflow-y: scroll;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px ;
    margin: 12px ;
    padding: 12px 24px;
}