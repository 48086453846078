.gas-price-modal{
    --min-height:50vh;
    --height:auto;
    /*--height:auto;*/
    --border-radius:15px 25px 0 0;

}

.confirm-transaction-modal{
    --min-height:80vh;
    --height:auto;
    --border-radius:15px 25px 0 0;
}

.gas-price-modal .modal-wrapper{
    position: absolute;
    bottom: 0;
}

.confirm-transaction-modal .modal-wrapper{
    position: absolute;
    bottom: 0;
}

.work-break{
    word-break: break-all;
    max-height: 100px;
    overflow-y: scroll;
}

.net-progress{
    height: 10px;
    --progress-background: repeating-linear-gradient(90deg, #76ab61, #76ab61 2px, #ddd 2px, #ddd 4px);
}

.net-div{
    padding: 5px 15px;
}