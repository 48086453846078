.address-wrap{
    word-break: break-all;
    padding: 5px;
    color: #333333;
}

.icon-coin{
    border: 1px solid #ddd;
}

.address-list-head{
    border-radius: 5px;
}

.coin-chain-eth{
    position: absolute;
    left: 15px;
    font-size: 50%;
    bottom: 0;
    font-weight: 600;
}

.coin-chain-bsc{
    position: absolute;
    left: 25px;
    font-size: 50%;
    bottom: 0;
    font-weight: 600;
}

.coin-chain-sero{
    position: absolute;
    left: 25px;
    font-size: 50%;
    bottom: 0;
    font-weight: 600;
}

.coin-chain-tron{
    position: absolute;
    left: 25px;
    font-size: 50%;
    bottom: 0;
    font-weight: 600;
}

.total-amount{
    font-size: 20px;
    letter-spacing: .019em;
    font-weight: 700;
    color: #111;
}

.wallet-card-content{
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.epoch-version-modal{
    --width: 90%;
    --height: 60%;
    --border-radius: 15px;
    --background:#fff;
}

.version-content{
    min-height: 35vh;
    max-height: 40vh;
    overflow-y: scroll;
}

.version-button{
    position: absolute;
    bottom: 0;
    width: 100%;
}