@font-face{
    font-family: 'Epilogue';
    src : url('./theme/epilogue.woff2');
}
/**{*/
/*    font-family: Epilogue,sans-serif;*/
/*}*/

/*body{*/
/*    font-family: Epilogue,sans-serif;*/
/*}*/

ion-slides {
    height: 90vh
}

.toolbar-icon{
    width: 30px;
}

.toolbar-cust{
    padding: 10px 0;
}