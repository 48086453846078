
.card-page {
  height: auto;
  //background: #000;
  //min-height: 100vh;
  padding: 12px;
}

.card-inset {
  border: 2px dashed #ddd;
  border-radius: 0.375em;
  min-height: 65vh;
  height: auto;
}

.n-card {
  margin: 12px 6px 0;
  //height: calc(100vw + 125px);
  //max-height: 512px;
  color: #000;
  padding: 0 12px;
}

.card-foo {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: #ddd;
}

.row-line {
  //border: 1px solid #555;
  text-align: center;
}

.col-line {
  border: 1px solid #555;
}

.card-p {
  word-break: break-word;
  //height: 80px;
  overflow: scroll;
}

.card-display {
  position: relative;
  transition: transform 0.6s ease 0.1s;
  transform-style: preserve-3d;
  /* width: 220px; */
  /* height: 305.938px; */
  transform: rotateY(
                  180deg
  );
  margin: auto;
  //background-color: #000;
  max-width: 512px
}

.card-front {
  //position: absolute;
  z-index: 10;
  //background: #FFF;
  //background: #fff;
  transform: rotateY(
                  0deg
  ) perspective(1529.69px);
  backface-visibility: visible;
  transform: perspective(1529.69px);
  overflow: hidden;
  transition: transform 1s cubic-bezier(.445, .05, .55, .95);
  //box-shadow: 0 0 5px 1px #ddd;
  border-radius: 0.375em;
  backface-visibility: hidden;
  //padding: 30px;
}

.card-back {
  position: absolute;
  z-index: 9;
  //background: #fff;
  transform: rotateY(
                  180deg
  );
  overflow: hidden;
  transition: transform 1s cubic-bezier(.445, .05, .55, .95);
  box-shadow: 0 0 5px 1px #ddd;
  border-radius: 0.375em;
  backface-visibility: hidden;
  //padding: 30px;
  border: 1px solid #ddd;

}

.card-back-f {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #FFF;
  opacity: 0.9;
  overflow: scroll;
  //transform: rotateY(180deg);
  //border: 1px solid #000;
  //border-radius: 5px;
}

.dark-element-bg {
  background-image: -webkit-radial-gradient(circle, #000 5%, #960404 65%, #000 70%);
  //animation: pulsate1 6s linear infinite;
  border-radius: 5px 5px 0 0;
  //padding: 35px;
  max-width: 512px;
}

.light-element-bg {
  background: -webkit-radial-gradient(circle, #fff 5%, #fff 65%, #152955 70%);
  //background-image: -webkit-radial-gradient(circle, #000 5%, #960404 65%, #000 80%);
  border-radius: 5px 5px 0 0;
  //padding: 35px;
  max-width: 512px;
}

.pd-35{
  padding: 12%;
}

.card-desc-default{
  background-color: #fff;
  //opacity: 0.5;
  text-align: center;
}

.card-desc-light{
  background-color: #1a2952;
  //opacity: 0.5;
  color: #fff;
  text-align: center;
}

.card-desc-dark{
  background-color: #000 !important;
  //opacity: 0.5;
  color: #fff;
  text-align: center;
}

@keyframes pulsate1 {
  50% {
    box-shadow: 0 0 10px #fff,
    10px 0 25px #f0f,
    -10px 0 25px #00ffff,
    inset 0 0 15px #fff,
    inset -15px 0 25px #f0f,
    inset 15px 0 25px #0ff,
    inset -15px 0 100px #f0f,
    inset 15px 0 100px #0ff
  }
}

.description{
  position: relative;
  margin: 0 auto;
  padding: 1em;
  max-width: 23em;
  background: hsla(0,0%,100%,.25) border-box;
  overflow: hidden;
  border-radius: .3em;
  box-shadow: 0 0 0 1px hsla(0,0%,100%,.3) inset,
  0 .5em 1em rgba(0, 0, 0, 0.6);
  text-shadow: 0 1px 1px hsla(0,0%,100%,.3);
}

.description::before{
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: -30px;
  z-index: -1;
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
.nft-card-name{
  //position: absolute;
  //bottom: 8%;
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  word-break: normal;
  word-wrap: normal;
  color: #fff;
}

.nft-star{
  position: absolute;
  top: 15px;
  right: 20px;
}