.pool-content {
  text-align: center;
  padding: 12px;
}

.pool-head {
  background: #194381;
  color: rgb(237, 204, 103);
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  padding: 6px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 12px;
  text-align: center;
}

.pool-item {
  border: 1px solid #194381;
  padding: 5px 0;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  margin: 12px 0;
}

.pool-item span {
  font-weight: 600;
  text-align: center;
}

.pool-modal{
  border: 1px solid #194381;
  padding: 12px;
  margin: 12px;
  border-radius: 5px;
  height: 80vh;
  overflow-y: scroll;
}

.pool-modal span{
  font-size: 14px;
  font-weight: 500;
}

.pool-display{
  border: 1px dashed #194381;
  padding: 6px 12px;
  margin: 20px 12px;
  border-radius: 5px;
  min-height: 20px;
}

.pool-display p{
  font-size: 12px;
  font-weight: 500;
}

.pool-info-item {
  border-bottom: 1px solid #ddd;
  padding: 6px 0;
  font-size: 14px;
  text-align: center;
}

.pool-info-detail{
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 5px 5px;
  padding: 12px;
  max-height: 65vh;
  overflow: scroll;
}

.pool-info-header span{
  font-size: 14px;
}

.pool-info-bottom{
  //bottom: 0;
  //position: sticky;
  width: 100%;
  background: #fff;
}
.pool-info-content{
}

.mining{
  position: absolute;
  right: -5px;
  top: 15px;
  transform: rotate(
                  45deg
  );
}

.no-wrap{
  //word-break: normal;
  //overflow-wrap: normal;
  //overflow: hidden;
  ///* width: 100%; */
  //text-overflow: ellipsis;
  //display: block;
}

.popover-customer{
  padding: 5px 12px;
}

.pool-countdown {
  text-align: right;
  font-size: 16px;
  color: red;
  padding: 6px 6px 6px;
}